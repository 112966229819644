import debounce from 'lodash.debounce';
import Swiper, { Autoplay } from 'swiper';

Swiper.use([Autoplay]);

const logoCarousels = document.querySelectorAll('.swiper-logos-carousel');
let isDown = false;
let startX = 0;
let initialTranslateX = 2000;
let scrollLeft = 0;

const setSliderWidth = slider => {
    const sliderWrapperEl = slider.querySelector('.logos-carousel__wrapper');
    const sliderWrapperRect = sliderWrapperEl.getBoundingClientRect();
    const sliderWrapperWidth = sliderWrapperRect.width;

    let sliderWidth = 0;

    let slides = slider.querySelectorAll('.logos-carousel__slide');
    slides = slider.querySelectorAll('.logos-carousel__slide');
    slides.forEach(item => {
        const itemRect = item.getBoundingClientRect();
        const { width } = itemRect;

        sliderWidth += width;
    });

    if (window.innerWidth > 992) {
        sliderWidth += 120 * (slides.length - 1) - sliderWrapperWidth + 60;
    } else {
        sliderWidth += 32 * (slides.length - 1) - window.innerWidth + 60;
    }

    return sliderWidth;
};

logoCarousels.forEach(slider => {
    const sliderWrapperEl = slider.querySelector('.logos-carousel__wrapper');

    let slides = slider.querySelectorAll('.logos-carousel__slide');
    slides.forEach(slideEl => {
        const slideNode = slideEl.cloneNode(true);
        sliderWrapperEl.appendChild(slideNode);
    });

    let timer = null;
    let animationTime = 10 * 1000; // in ms
    let sliderWidth = setSliderWidth(slider);
    let pixelsThreshold = sliderWidth / animationTime;

    //sliderWrapperEl.addEventListener('mousedown', event => startDrag(event));
    //sliderWrapperEl.addEventListener('touchstart', event => startDrag(event));

    //sliderWrapperEl.addEventListener('mousemove', event => move(event));
    //sliderWrapperEl.addEventListener('touchmove', event => move(event));

    sliderWrapperEl.addEventListener('mouseenter', event =>
        onSliderMouseEnter()
    );
    sliderWrapperEl.addEventListener('mouseleave', event => startEnd());

    window.addEventListener('click', (event) => {
        if (!sliderWrapperEl.contains(event.target)) {
            if (timer !== null) {
                clearInterval(timer);
                timer = null;
            }

            startEnd();
        }
    })
    
    window.addEventListener('resize', onResize);

    const pauseMarquee = () => {
        if (timer !== null) {
            clearInterval(timer);
            timer = null;
        }
    };

    const animateMarquee = () => {
        timer = setInterval(() => {
            initialTranslateX += pixelsThreshold;
            if (initialTranslateX > sliderWidth) initialTranslateX = 0;
            sliderWrapperEl.style.transform = `translateX(${-initialTranslateX}px)`;
        }, 5);
    };

    const onSliderMouseEnter = () => {
        pauseMarquee();
    };

    const onWindowResize = () => {
        sliderWidth = setSliderWidth(slider);
        pauseMarquee();

        setTimeout(animateMarquee, 1000);
    };
    const onResize = debounce(onWindowResize, 1000);

    const startDrag = e => {
        e.preventDefault();
        isDown = true;

        sliderWrapperEl.classList.add('drag');
        startX = e.pageX || e.touches[0].pageX;
        const transformStyle =
            window.getComputedStyle(sliderWrapperEl).transform;

        scrollLeft = -initialTranslateX;
    };

    const move = e => {
        if (!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX;
        const dist = x - startX;

        const dragTranslate = -initialTranslateX - dist;
        sliderWrapperEl.style.transform = `translateX(${-dragTranslate}px)`;
    };

    const startEnd = () => {
        isDown = false;
        sliderWrapperEl.classList.remove('drag');
        animateMarquee();
    };

    animateMarquee();
});
